.home {
	.carousel-item {
		img {
			height: 60vh;
			object-fit: cover;
			filter: brightness(85%);
			object-position: center;

			@media (max-width: 992px) {
				height: 40vh;
			}
		}
	}

	.aboutNSUT {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			border-width: 15px;
			border-style: solid;
			border-color: #fff transparent transparent transparent;
		}
		position: relative;
		margin: 1rem 0;
		min-height: 100%;
		// background-image: url('/campus/3.jpeg');
		background-size: cover;
		background-position: center right;
		box-shadow: inset 0 0 0 2000px rgba(23, 64, 78, 0.81);
		padding: 2rem 0;

		.container {
			display: flex;
			padding-top: 2rem;
			padding-bottom: 2rem;
			align-items: center;
			justify-content: space-between;
			position: relative;
			.text {
				color: white;
				flex-basis: 75%;
				padding-right: 3rem;
				h1 {
					margin-bottom: 1rem;
				}

				p {
					color: #a9a9a9;
				}
				.redirect-btn {
					&:hover {
						background-color: #388e3c;
					}
					text-decoration: none;
					color: #fff !important;
					padding: 0.6rem 1rem;
					background-color: #27ae60;
					border-radius: 4px;
				}
			}
			.about-img {
				flex-basis: 25%;
				img {
					height: 25vw;
					width: 25vw;

					object-fit: cover;
					border-radius: 4px;
				}
			}

			@media (max-width: 992px) {
				flex-direction: column;
				.text {
					padding-right: 0;
					padding-bottom: 2rem;
				}
				.about-img {
					width: 100%;
					// flex-basis: 25%;
					img {
						width: 100%;
						// padding: 1rem;
						height: fit-content;
						max-height: 400px !important;
					}
				}
			}
		}
	}
	.whyNSUT {
		// padding: 2rem 0;
		margin: 2rem auto;
		h1 {
			margin-bottom: 3rem;
			color: #424242;
			font-size: 2.2rem;
		}
		.f-row {
			display: flex;
			flex-basis: 50%;
			align-items: baseline;
			justify-content: space-between;
			flex-direction: row;
			margin: 2rem auto;
			color: #424242;
			flex-shrink: 0;

			.f-col {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				flex-direction: row;
				margin: 0 1rem;
				flex-basis: 50%;
				.image-wrapper {
					img {
						height: 70px;
						width: 70px;
						// border-radius: 100px;
						margin-right: 1.5rem;
					}
				}

				h2 {
					font-size: 25px;
					color: #17404e;
				}

				p {
					margin-bottom: 0.5rem;
				}

				a.learn {
					color: #27ae60;
					text-decoration: none;
					cursor: pointer;
					font-weight: 500;
				}
			}
		}

		@media (max-width: 992px) {
			h1 {
				text-align: center;
			}
			.f-row {
				flex-basis: 100%;
				flex-direction: column;
				margin: auto;

				.f-col {
					flex-direction: column;
					text-align: center;
					margin: 2rem auto;
					align-items: center;
					img {
						margin-right: 0;
						margin-bottom: 1rem;
					}
					p {
						margin: 0;
					}
				}
			}
		}
	}
}
